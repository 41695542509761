import React, {useState} from "react";
import "./App.css";
// import "animate.css";
import Dashboard from "./pages/Dashboard";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";

const App = () => {

    const [theme, setTheme] = useState('dark');
    const toggleTheme = () => {
        if (theme === 'light') {
            setTheme('dark');
        } else {
            setTheme('light');
        }
    }

    const darkTheme = createMuiTheme({
        overrides: {
            MuiPaper: {},
        },
        palette: {
            type: "dark",
            primary: {
                main: '#2d88ff',
                secondary: '#fff'
            },
            background: {
                default: 'rgba(12,12,12,0.95)',
                paper: 'rgba(29,30,34)',
            }
        },
        status: {
            danger: 'orange',
        },
    });

    const lightTheme = createMuiTheme({
        overrides: {
            MuiPaper: {},
        },
        palette: {
            type: "light",
            primary: {
                main: '#2d88ff',
                secondary: '#fff'
            },
        },
        status: {
            danger: 'orange',
        },
    });

    return (
        <MuiThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
            <div elevation={0} className="App">
                <Dashboard toggleTheme={toggleTheme} theme={theme}/>
            </div>
        </MuiThemeProvider>
    );
}

export default App;
