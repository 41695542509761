import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ListItemText from "@material-ui/core/ListItemText";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import {jobData} from "./util/jobData";
import Divider from "@material-ui/core/Divider";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {usePageVisibility} from 'react-page-visibility';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: 0
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    item: {
        padding: '0 0 0 7px',
        margin: 0,
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    row: {
        flexDirection: "row"
    },
    column: {
        flexBasis: '33.33%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

const getProjectContent = (project, classes) => {

    return <div className={classes.root}>
        <List disablePadding={true} dense={true}>
            {project.content.map((note, index, projects,) => {
                return <div>
                    <ListItem className={classes.item} alignItems="flex-start">
                        <ListItemText color="textPrimary" className={classes.item}
                                      primary={`•\t\t ${note}`}
                        />
                    </ListItem>
                </div>
            })}
        </List>
    </div>

}

export const getProjects = (projects, classes) => {

    return <List disablePadding={true} className={classes.root}>
        {projects.map((project, index, projects,) => {
            return <div>
                <ListItem alignItems="flex-start">
                    {/*<ListItemAvatar>*/}
                    {/*    <Avatar>{project.projectName.substr(0, 1)}</Avatar>*/}
                    {/*</ListItemAvatar>*/}
                    <ListItemText
                        primary={
                            <div className={classes.root}>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1c-content"
                                        id="panel1c-header"
                                    >
                                        <Grid container>
                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <Typography className={classes.heading}>{project.client}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <Box alignItems="center" display="flex">
                                                    <Box>
                                                        <AssignmentIndIcon color={"primary"}></AssignmentIndIcon>
                                                    </Box>
                                                    <Box>
                                                        <Typography
                                                            className={classes.secondaryHeading}>{project.role}</Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <Box alignItems="center" display="flex">
                                                    <Box>
                                                        <DateRangeIcon color={"primary"}></DateRangeIcon>
                                                    </Box>
                                                    <Box>
                                                        <Typography
                                                            className={classes.secondaryHeading}>{project.duration}</Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Divider light/>


                                        </Grid>

                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className={classes.details}>
                                        <Typography variant="body2">
                                            {`${project.projectName}: ${project.projectDesc}`}
                                            {getProjectContent(project, classes)}
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>

                            // <div>
                            //     <ExpansionPanelSummary classes={{content: classes.summary}} expandIcon={<SomeIcon/>}>
                            //         <Typography
                            //             component="span"
                            //             variant="body2"
                            //             className={classes.inline}
                            //         >
                            //             {`${project.projectName}: ${project.projectDesc}`}
                            //         </Typography>
                            //         <Typography noWrap
                            //                     title={experiment.name}> {getProjectContent(project, classes)}</Typography>
                            //     </ExpansionPanelSummary>
                            //
                            // </div>
                        }
                    />
                </ListItem>
            </div>
        })}
    </List>
}

const ExperiencePage = () => {
    const classes = useStyles();
    const isVisible = usePageVisibility();
    console.log(isVisible)

    return (
        <Stepper orientation="vertical" nonLinear={true} classes={{
            root: classes.root,
        }}>
            {
                jobData.map((job, index) => {
                    return <Step active={true} key={index} classes={{
                        root: classes.root,
                    }}>
                        <StepLabel>
                            <Typography> {job.title} </Typography>
                            <Typography color={"textSecondary"}>{job.organization} | {job.duration}</Typography>
                        </StepLabel>
                        <StepContent classes={{
                            root: classes.MuiStepContent,
                        }}>
                            {getProjects(job.projects, classes)}
                        </StepContent>
                    </Step>
                })}
        </Stepper>
    )
};

export default ExperiencePage;