export const skillData = [
    {
        skillTitle: "Backend",
        skills: [
            {
                title: "Java",
                image: "images/skills/backend/java.png",
                rating: '90',
            },
            {
                title: "Spring",
                image: "images/skills/backend/spring.png",
                rating: '95',
            },
            {
                title: "Spring Boot",
                image: "images/skills/backend/springboot.png",
                rating: '95',
            },
            {
                title: "Spring Cloud",
                image: "images/skills/backend/springcloud.png",
                rating: '90',
            },
        ]

    },
    {
        skillTitle: "Frontend",
        skills: [
            {
                title: "HTML+CSS+JS",
                image: "images/skills/frontend/html.png",
                rating: '80',
            },
            {
                title: "React",
                image: "images/skills/frontend/react.png",
                rating: '80',
            },
            {
                title: "Angular Js",
                image: "images/skills/frontend/angular.png",
                rating: '75',
            },
            {
                title: "Node Js",
                image: "images/skills/frontend/nodejs.png",
                rating: '70',
            },
            {
                title: "Material UI",
                image: "images/skills/frontend/material.png",
                rating: '80',
            },
            {
                title: "Bootstrap UI",
                image: "images/skills/frontend/bootstrap.png",
                rating: '80',
            }
        ]

    },
    {
        skillTitle: "Databases",
        skills: [
            {
                title: "MongoDB",
                image: "images/skills/db/mongo.png",
                rating: '90',
            },
            {
                title: "MySql",
                image: "images/skills/db/mysql.png",
                rating: '85',
            },
            {
                title: "Oracle",
                image: "images/skills/db/oracle.png",
                rating: '80',
            },
            {
                title: "DB2",
                image: "images/skills/db/db2.png",
                rating: '80',
            },
        ]

    },{
        skillTitle: "MiddleWare",
        skills: [
            {
                title: "Apache Kafka",
                image: "images/skills/middleware/kafka.png",
                rating: '90',
            },
            {
                title: "ActiveMQ",
                image: "images/skills/middleware/activemq.png",
                rating: '90',
            }
        ]

    },
    {
        skillTitle: "Tools",
        skills: [
            {
                title: "IntelliJ",
                image: "images/skills/tools/intellij.png",
                rating: '90',
            },
            {
                title: "Mongo Compass",
                image: "images/skills/tools/mongo.png",
                rating: '90',
            },
            {
                title: "Kafka Tool",
                image: "images/skills/tools/kafkatool.png",
                rating: '90',
            }
        ]

    }
]