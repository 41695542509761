export const jobData = [
    {
        title: "Lead Engineer",
        duration: "September,2021 - Present",
        organization: "Target Technologies Inc.",
        projects: [
           {
                client: "Target",
                location: "",
                projectName: "Inventory Move",
                projectDesc: "The goal of this project is to systematically move inventory to storage areas.",
                role: "Senior Web Developer",
                duration: "September,2021 - Present",
                content: [
                  "Collect business requirements and convert them to design documents for Invenotry Move.",
                  "Working on micro services implementation using kotlin, micronaut, Kafka, Mongo db.",
                  "Responsible in Unit Testing with Junit and DB Unit Test cases.",
                  "Maintain technical documentation and user manual preparation.",
                ]
            }
        ]
    },
    {
        title: "Technical Lead",
        duration: "May,2017 - September,2021",
        organization: "Gspann Technologies Inc.",
        projects: [
           {
                client: "Starbucks",
                location: "",
                projectName: "GRED (Global Retail Engineering Delivery)",
                projectDesc: "The goal of this project is to implement domain driven micro services using latest tech stack to support GRED Platform in starbucks.",
                role: "Senior Web Developer",
                duration: "April,2020 - Present",
                content: [
                  "Collect business requirements and convert them to design documents for GRED program.",
                  "Working on domain driven micro services implementation using kotlin, micronaut, Kafka, Scylla db.",
                  "Responsible in Unit Testing with Junit and DB Unit Test cases.",
                  "Maintain technical documentation and user manual preparation.",
                  "Migrated few legacy apps to micronaut microservices with new cloud architecture on docker and Kubernetes.",
                ]
            },
            {
                client: "Kohl’s Department Stores, Inc.",
                location: "",
                projectName: "Kohl’s Rewards - Loyalty",
                projectDesc: "The goal of this project is to implement new kohls loyalty program with event driven micro services using latest tech stack.",
                role: "Senior Web Developer",
                duration: "September,2017 - April 2020",
                content: [
                    "Collect business requirements and convert them to design documents for loyalty program.",
                    "Working on event driven micro services implementation using spring boot, Kafka, mongo db.",
                    "Working on various spring batch jobs required for loading data, sending feeds to external systems etc. using google cloud storage.",
                    "Worked on event driven approach for new loyalty feature.",
                    "Migrated few legacy apps to new cloud architecture.",
                ]
            },
            {
                client: "Kohl’s Department Stores, Inc.",
                location: "",
                projectName: "Kohl's Platform Re-architecture",
                projectDesc: "The goal of this project is to implement an e-commerce application migration from Oracle ATG Com-merce to Open source platform. This involves moving the complete e-commerce store implementation in Micro services, Apache Kafka, Redis Cache, Mongo DB.",
                role: "Senior Software Engineer",
                duration: "May,2017 - September, 2017",
                content: [
                    "Collect business requirements and convert them to design documents for migration.",
                    "Migrated few legacy apps to new cloud architecture.",
                    "Working on rest services for Search and Browse module.",
                    "Working on various batch jobs for required for loading data, sending feeds to external systems, loading cache, etc.",
                    "Configured Apache Kafka with various topics to establish a messaging framework in Google cloud.",
                    "Working on the validation of all the ATG functionality to be covered in the re-architecture frame-work.",
                    "Perform root cause analysis of the reported unfavorable production issues."
                ]
            }
        ]
    }
    ,
    {
        title: "Software Engineer",
        duration: "September,2013 - November,2016",
        organization: "OSI Technologies",
        projects:
            [
                {
                    client: "Macy's Inc., Bloomingdales",
                    location: "",
                    projectName: "Async Checkout",
                    projectDesc: "The goal of the project is to place order asynchronously, when user places an order the address and card validation will be done asynchronously so that user won't wait for backend response. User would place an order and if something went wrong with address and card validation, he will be sent a mail say-ing your order is declined with a link to update his/her payment.",
                    role: "UI Engineer",
                    duration: "January,2016 – November,2016",
                    content: [
                        "Responsible in coding of assigned tasks and following agile methodology.",
                        "Worked on front end side (UI) using backbone and marionette frameworks.",
                        "Fixing defects and enhancements.",
                        "Implementing Enhancements and code review.",
                        "Responsible in Unit Testing with JUNIT and DB Unit Test cases.",
                        "Maintain technical documentation and user manual preparation.",
                        "Monitor logs using Splunk in production."
                    ]
                },
                {
                    client: "Macy's Inc., Bloomingdales",
                    location: "",
                    projectName: "User Identity and Access Management",
                    projectDesc: "This project focuses more User profile password reset and forgot password functionality. When user clicks on reset password button, will generate OTP and construct URL and sends to his registered mail. User will be navigated to reset password page when he clicks on URL received in his mail. Validation of OTP takes place when he clicks on link.",
                    role: "Software Engineer",
                    duration: "October,2015 – January,2016",
                    content: [
                        "Collect business requirements and convert them to design documents.",
                        "Responsible in coding of assigned tasks and following agile methodology.",
                        "Automated Scenarios using ruby selenium.",
                        "Fixing bugs and enhancements.",
                        "Implementing Enhancements and code review.",
                        "Maintenance and Defect Fixing.",
                        "Technical documentation and user manual preparation.",
                        "Code walkthrough.",
                        "Responsible in Unit Testing with JUNIT and DB Unit Test cases."
                    ]
                },
                {
                    client: "Macy's Inc., Bloomingdales",
                    location: "",
                    projectName: "Macys Money and Bloomingdales Money",
                    projectDesc: "MBMoney (Macys Money and Bloomingdales Money): If customer made purchase in one period (earn period) on site or in store, he will earn some money and this amount will be automatically added to his/her wallet. The saved money can be used in another period (re-deem period). The earn and redeem periods are handled by configuring campaigns.",
                    role: "Software Engineer",
                    duration: "May,2014 – October,2015",
                    content: [
                        "Collect business requirements and convert them to design documents.",
                        "Responsible in coding of assigned tasks and following agile methodology.",
                        "Automating scenarios using ruby selenium.",
                        "Fixing bugs and enhancements .",
                        "Implementing Enhancements and code review.",
                        "Technical documentation and user manual preparation.",
                        "Responsible in Unit Testing with JUNIT and DB Unit Test cases.",
                        "Monitor logs using Splunk in production."
                    ]
                },
                {
                    client: "Macy's Inc., Bloomingdales",
                    location: "",
                    projectName: "Omni Channel Wallet",
                    projectDesc: "The goal of the project is to add new feature wallet for storing offers, loyalty cards and credit cards in site for user account. User can add manually offers, credit cards and loyalty cards to his/her wallet.",
                    role: "Automation Engineer",
                    duration: "September,2013 – May,2014",
                    content: [
                        "Collect business requirements and convert them to design documents.",
                        "Responsible in coding of assigned tasks and following agile methodology.",
                        "Automating the scenarios using ruby-selenium.",
                        "Fixing defects and enhancements.",
                        "Implementing Enhancements and code review.",
                        "Responsible in Unit Testing with JUNIT and DB Unit Test cases.",
                        "Maintain technical documentation and user manual preparation.",
                        "Monitor logs using Splunk in production.",
                    ]
                }
            ]
    }
]
