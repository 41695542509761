import React from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import EmailIcon from "@material-ui/icons/Email";
import ListItemText from "@material-ui/core/ListItemText";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import {Fade} from "react-reveal";


const post = {
    title: 'Narendra Aakula',
    description: "Multiple lines of text that form the lede, informing new readers quickly and efficiently about what's most interesting in this post's contents.",
    image: 'images/narendra_banner.png',
    imageText: 'Narendra Aakula',
    linkText: 'Continue reading…',
};

const useStyles = makeStyles((theme) => ({
    homeBanner: {
        position: 'relative',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        backgroundImage: 'url(images/narendra_banner.png)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
//        backgroundPosition: 'center',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.3)',
    },
    homeBannerContent: {
        position: 'relative',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6),
            paddingRight: 0,
        },
    },
}));

export default function HomeBanner() {
    const classes = useStyles();
    return (
        <Fade top>
            <Paper className={classes.homeBanner} style={{backgroundImage: `url(${post.image})`}}>
                {<img style={{display: 'none'}} src={post.image} alt={post.imageText}/>}
                <div className={classes.overlay}/>
                <Grid container>
                    <Grid item>
                        <div className={classes.homeBannerContent}>
                            <Typography component="h5" variant="h4" color="inherit" gutterBottom>
                                {post.title}
                            </Typography>
                            <Typography variant="h6" color="inherit" paragraph>
                                <Typography variant="h6" className={classes.title}>
                                    Java full stack developer
                                </Typography>
                            </Typography>
                            <div className={classes.demo}>
                                <List dense={'true'}>
                                    <ListItem>
                                        <ListItemIcon>
                                            <EmailIcon color={"primary"}/>
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Link
                                                href="mailto:aakulanarendra91@gmail.com"
                                                target="_blank">
                                                aakulanarendra91@gmail.com
                                            </Link>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <LinkedInIcon color={"primary"}/>
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Link href="http://www.linkedin.com/in/aakulanarendra"
                                                  target="_blank">
                                                linkedin.com/in/aakulanarendra
                                            </Link>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <FacebookIcon color={"primary"}/>
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Link href="https://www.facebook.com/aakulanarendra91"
                                                  target="_blank">
                                                facebook.com/aakulanarendra91
                                            </Link>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </div>
                            {/*<Link variant="subtitle1" href="#">*/}
                            {/*    {post.linkText}*/}
                            {/*</Link>*/}
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </Fade>
    );
}


