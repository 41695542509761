import React, {useEffect, useRef, useState} from 'react'
import AboutPage from "./About";
import SkillPage from "./Skill";
import EducationPage from "./Education";
import ContactPage from "./Contact";
import HomeBanner from "./HomeBanner";
import {makeStyles} from "@material-ui/core/styles";
import ExperiencePage from "./Experience";
import SchoolIcon from '@material-ui/icons/School';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import Section from "../components/Section";
import {Fade} from "react-reveal";
import FooterPage from "./Footer";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Tooltip from "@material-ui/core/Tooltip";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '5% 0',
        [theme.breakpoints.up('sm')]: {
            padding: '5% 10%'
        }
    },
    fab: {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        position: 'fixed'
    },

    themeFab: {
        top: theme.spacing(2),
        right: theme.spacing(2),
        position: 'fixed'
    },

    media: {
        paddingTop: '40.25%', // 16:9,
    },
    content: {
        padding: '0',
    },
    cardBar: {
        left: '20px',
        top: '20px',
        marginLeft: '20px',
        width: '50%',
        color: '#fff',
        display: 'flex',
        position: 'absolute',
        background: 'rgba(0, 0, 0, 0.5)',
        alignItems: 'center',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    }
}));


const Dashboard = (props) => {

    const classes = useStyles()
    const scrollRef = useRef();
    const [showFab, setShowFab] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY > 200) {
                setShowFab(true);
            } else {
                setShowFab(false);
            }
        };
        window.addEventListener("scroll", handleScroll, {passive: true});
        return () => window.removeEventListener("scroll", handleScroll);
    }, [showFab]);


    const onFabPress = () => {
        window.scroll({top: 0, behavior: 'smooth'})
    }

    return (
        <>
            <div ref={scrollRef} className={classes.root}>
                <Section
                    title=""
                    Animation={<Fade/>}
                    icon={<AccountCircleIcon/>}
                    subtitle={<HomeBanner/>}
                    dark={false}
                    id="section1"
                />
                <Section
                    title="About"
                    icon={<AccountCircleIcon/>}
                    subtitle={<AboutPage/>}
                    dark={false}
                    id="section1"
                />
                <Section
                    title="Skill"
                    icon={<LibraryBooksIcon/>}
                    subtitle={<SkillPage/>}
                    dark={false}
                    id="section2"
                />
                <Section
                    title="Experience"
                    icon={<SchoolIcon/>}
                    subtitle={<ExperiencePage/>}
                    dark={false}
                    id="section3"
                />
                <Section
                    title="Education"
                    icon={<SchoolIcon/>}
                    subtitle={<EducationPage/>}
                    dark={false}
                    id="section3"
                />
                <Section
                    title="Contact"
                    icon={<ContactMailIcon/>}
                    subtitle={<ContactPage/>}
                    dark={false}
                    id="section4"
                />
            </div>
            <Zoom
                in={showFab}
                timeout={1000}
                style={{
                    transitionDelay: `${0}ms`,
                }}
                unmountOnExit
            >
                <Tooltip title="Go To Top" aria-label="gototop">
                    <Fab size="small" aria-label={'Expand'} className={classes.fab} color={'primary'}
                         position="bottomRight"
                         onClick={onFabPress}>
                        <UpIcon/>
                    </Fab>
                </Tooltip>

            </Zoom>
            <Tooltip title="Toggle light/dark theme" aria-label="theme">
                <Fab size="small" aria-label={'Expand'} className={classes.themeFab} color={'primary'}
                     position="bottomRight"
                     onClick={props.toggleTheme}>
                    {props.theme === 'light' ? <Brightness4Icon/> : <Brightness7Icon/>}
                </Fab>
            </Tooltip>

            <FooterPage></FooterPage>
        </>
    )
}


export default Dashboard
