import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid"
import makeStyles from "@material-ui/core/styles/makeStyles";
import {skillData} from "./util/skillData";
import {Avatar} from '@material-ui/core';
import CustomProgressBar from "../components/LinearProgressWithLabel";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItem from "@material-ui/core/ListItem";


const useStyles = makeStyles((theme) => ({
    Card: {
        padding: '0px'
    },
    avatar: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'dark' ? 700 : 200],

    },
    content: {
        padding: '10px 0px 0px 0px'
    },
    skillContent: {
        border: '1px solid theme.palette.grey[theme.palette.type === \'dark\' ? 700 : 200]',
    },
    root: {
        flexGrow: 1,
        padding: '0px 10px 0px 10px'
    },
    media: {
        height: 30,
        paddingTop: '30.25%', // 16:9,
        marginTop: '30'
    },
    paper: {
        marginBottom: theme.spacing(4),
        elevation: 0,
        color: theme.palette.text.secondary,
        padding: '10px'
    },
    title: {
        fontSize: 14,
        textAlign: 'center'

    },
    large: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        border: '2px solid red'
    },
}));

const SkillPage = () => {
    const classes = useStyles();
    return (
        <>
            <Grid container spacing={3}>
                {
                    skillData.map((skillType, index) => {
                        return <>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                <Paper elevation={0} className={classes.skillContent} fixed>
                                    <Typography gutterBottom variant="h6" marked="center" align="center" component="h6">
                                        {skillType.skillTitle}
                                    </Typography>
                                    <Divider></Divider>
                                    <br/>
                                    <div className={classes.demo}>
                                        <List dense={true}>
                                            {skillType.skills.map((skill, skillIndex) => {
                                                return <ListItem>
                                                    <ListItemAvatar>
                                                        <Avatar className={classes.avatar} alt={skill.title}
                                                                src={skill.image}/>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={<CustomProgressBar value={skill.rating}/>}
                                                        secondary={skill.title}
                                                    />
                                                </ListItem>
                                            })}
                                        </List>
                                    </div>
                                </Paper>
                            </Grid>
                        </>
                    })
                }
            </Grid>
        </>
    )
};
export default SkillPage;