import React from 'react';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import EmailIcon from "@material-ui/icons/Email";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import Typography from "@material-ui/core/Typography";
import {Box} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    footer: {
        paddingBottom: theme.spacing(4),
    },

}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary">
            {'Copyright © '}
            {'aakulanarendra'}
            {' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function SocialIcons() {
    return (
        <Box item>
            <IconButton size={"large"} color="primary" aria-label="Mail"
                        href="mailto:aakulanarendra91@gmail.com"
                        target="_blank">
                <EmailIcon fontSize="large"/>
            </IconButton>
            <IconButton size={"large"} color="primary" aria-label="LinkedIn"
                        href="http://www.linkedin.com/in/aakulanarendra" target="_blank">
                <LinkedInIcon fontSize="large"/>
            </IconButton>
            <IconButton size={"large"} color="primary" aria-label="Facebook"
                        href="https://www.facebook.com/aakulanarendra91" target="_blank">
                <FacebookIcon fontSize="large"/>
            </IconButton>
            <IconButton size={"large"} color="primary" aria-label="Twitter"
                        href="https://twitter.com/aakulanarendra" target="_blank">
                <TwitterIcon fontSize="large"/>
            </IconButton>
        </Box>
    );
}


export default function FooterPage(props) {
    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
            >
                <SocialIcons/>
                <Copyright/>
            </Grid>
        </footer>
    );
}

