import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Link from "@material-ui/core/Link";
import ListItemText from "@material-ui/core/ListItemText";
import TodayIcon from '@material-ui/icons/Today';
import SchoolIcon from '@material-ui/icons/School';

const EducationPage = () => {
    return (
        <>
            <List dense={'true'}>
                <ListItem>
                    <ListItemIcon>
                        <SchoolIcon color={"primary"}/>
                    </ListItemIcon>
                    <ListItemText
                        primary="Bachelor of Technology"
                        secondary="Graduation"
                    />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <SchoolIcon color={"primary"}/>
                    </ListItemIcon>
                    <ListItemText>
                        <Link href="https://www.kluniversity.in/" target="_blank">
                            KL University
                        </Link>
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <TodayIcon color={"primary"}/>
                    </ListItemIcon>
                    <ListItemText
                        primary="April, 2013"
                        secondary="Year"
                    />
                </ListItem>
            </List>
        </>
    )
};

export default EducationPage;