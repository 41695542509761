import React from "react";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import SectionHeader from "./SectionHeader";
import HeadShake from "react-reveal/HeadShake";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: "10px 0px",
        margin: '10px'
    },
    section: {
        paddingBottom: theme.spacing(4)
    }
}));

export default function Section({animation, title, subtitle, dark, id, icon}) {
    const classes = useStyles();
    return (
        <Paper elevation={0}>
            <div id={id} className={classes.root}>
                {title &&
                <HeadShake>
                    <SectionHeader className={classes.section} variant="h4" marked="center" align="center"
                                   component="h4">
                        {title}
                    </SectionHeader>

                </HeadShake>
                }
                {subtitle}
            </div>
        </Paper>
    );
}
