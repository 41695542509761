import React from "react";
import Typography from "@material-ui/core/Typography";
import {Zoom} from "react-reveal";


export default function AboutPage() {
    return (
        <Zoom>
            <Typography variant="h5" component="h2">
                Hi,
            </Typography>
            <Typography variant="h5" component="h2">
                I'm Narendra Aakula,
            </Typography>
            <br/>
            <Typography variant="body2" color="inherit" component="p">

                Full stack Java developer with 7+ years of experience in IT industry, which includes
                study, analysis,
                development, testing and support web based distributed n-tier and client/server
                architecture.
            </Typography>
            <br/>
            <Typography variant="body2" color="inherit" component="p">
                Strong Development skills in Java/J2EE, Spring/Spring Boot, Apache Kafka, MongoDB, Micro
                services, Google
                cloud, Ruby, HTML, CSS, JavaScript, Angular JS, ReactJS, NodeJS, Backbone, Marionette,
                Tomcat, JBoss and
                Operating Systems like Win- 2007 Server, UNIX.
            </Typography>
        </Zoom>
    )
}