import {LinearProgress} from '@material-ui/core';
import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import {Zoom} from "react-reveal";

const LinearProgressWithLabel = ((props) =>
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
);

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'dark' ? 700 : 200],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: theme.palette.primary.main,
    },
}))(LinearProgressWithLabel);

export default function CustomProgressBar(props) {
    return (
        <Zoom left>
            <BorderLinearProgress value={props.value}/>
        </Zoom>
    )
}