import React from 'react';
import clsx from 'clsx';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import makeStyles from "@material-ui/core/styles/makeStyles";
import emailjs from "emailjs-com";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {Fade} from "react-reveal";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const emailService = {
    emailServiceId: "gmail",
    templateId: "template_vkVs2tXk",
    userId: "user_kmGUuBbumagP8MB2yYpod"
}


export default function ContactPage(props) {
    const classes = useStyles();
    const [params, setParams] = React.useState({});
    const [alert, setAlert] = React.useState({open: false});
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({open: false});
    };


    let formref = React.createRef();

    function sendEmail(e, errors) {
        formref.isFormValid(false).then((isValid) => {
            if (isValid) {
                emailjs.send(emailService.emailServiceId, emailService.templateId, params, emailService.userId)
                    .then((result) => {
                        setAlert({open: true, severity: 'success', message: "Message sent to Narendra!"})
                        console.log(result.text);
                    }, (error) => {
                        setAlert({open: true, severity: 'error', message: "Failed to send message!"})
                        console.log(error.text);
                    });
            }
        });
    }

    function renderInputTextField(label, fieldName, required, validators) {
        if (required) {
            return <TextValidator
                required
                margin="dense"
                variant="outlined"
                label={label}
                fullWidth
                onChange={event => handleChange(event, fieldName)}
                name={label}
                className={clsx(classes.textField, classes.dense)}
                value={params[fieldName] || ""}
                validators={validators}
                errorMessages={['this field is required', 'Field is invalid']}
            />
        } else {

            return <TextField
                id="outlined-dense"
                label={label}
                fullWidth
                margin="dense"
                variant="outlined"
                className={clsx(classes.textField, classes.dense)}
                value={params[fieldName] || ""}
                onChange={event => handleChange(event, fieldName)}
            />
        }

    }

    const handleChange = (event, name) => {
        setParams({...params, [name]: event.target.value});
    };

    return (
        <Fade right>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <form className={classes.form} noValidate>
                    <Grid container direction="row">
                        <ValidatorForm
                            ref={(r) => {
                                formref = r;
                            }}
                            onError={errors => console.log(errors)}
                            onSubmit={sendEmail}
                        >

                            {renderInputTextField('Name', 'name', true, ['required'])}
                            {renderInputTextField('Email', 'email', true, ['required', 'isEmail'])}
                            {renderInputTextField('Subject', 'subject', true, ['required'])}

                            <TextValidator
                                required
                                margin="dense"
                                variant="outlined"
                                label={"Message"}
                                multiline
                                fullWidth
                                rows={4}
                                onChange={event => handleChange(event, "message")}
                                name={"Message"}
                                className={clsx(classes.textField, classes.dense)}
                                value={params["message"] || ""}
                                validators={["required"]}
                                errorMessages={['this field is required', 'Field is invalid']}
                            />
                            <Button fullWidth type={"submit"} variant="contained" color="primary">
                                Send
                            </Button>

                        </ValidatorForm>
                    </Grid>
                </form>
                <Snackbar open={alert.open} anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }} autoHideDuration={2000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={alert.severity}>
                        {alert.message}
                    </Alert>
                </Snackbar>


            </Container>
        </Fade>
    );
}

